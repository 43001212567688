var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TTableAdvance',{attrs:{"items":_vm.cards,"fields":_vm.fields,"store":"accounting.cards","resource":"/accounting/account/bank-card","enterable":"","creatable":""},on:{"click-clear-filter":_vm.clearFilter,"click-create":function($event){_vm.showModalCreate = true}},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.id,"noTranslate":""}})],1)]}},{key:"name",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.name,"noTranslate":""}})],1)]}},{key:"bank",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.bank)?_c('TMessage',{attrs:{"content":item.bank.name,"noTranslate":""}}):_c('TMessage',{attrs:{"content":item.bank_id,"noTranslate":""}})],1)]}},{key:"currency",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('SMessageCurrency',{attrs:{"id":item.currency_id}})],1)]}},{key:"account_id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('SMessageAccount',{attrs:{"value":item.account_id}})],1)]}},{key:"balance",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.balance,"currency":item.currency_id}})],1)]}},{key:"id-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.id},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "id", $event)},_vm.filterChange]}})]},proxy:true},{key:"name-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.name},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "name", $event)},_vm.filterChange]}})]},proxy:true},{key:"currency-filter",fn:function(){return [_c('SSelectCurrency',{attrs:{"value":_vm.filter.currency_id},on:{"update:value":function($event){return _vm.$set(_vm.filter, "currency_id", $event)},"change":_vm.filterChange}})]},proxy:true},{key:"account_id-filter",fn:function(){return [_c('SSelectAccount',{attrs:{"value":_vm.filter.account_id,"prepend":[{ id: '', name: _vm.$t('All') }]},on:{"update:value":function($event){return _vm.$set(_vm.filter, "account_id", $event)},"change":_vm.filterChange}})]},proxy:true}])}),_c('CardModal',{attrs:{"show":_vm.showModalCreate},on:{"update:show":function($event){_vm.showModalCreate=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }